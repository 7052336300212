/* AuthModal */
.AuthModal {
  height: 100vh;
  display: block;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: #F3F3F3;
  padding: 8px;
  z-index: 9999;
}

.am__dialog {
  position: relative;
  top: 50%;
  left: 50%;
  width: 510px;
  height: auto;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 7px;
  background-clip: padding-box;
  border-top: none;
}

.am__dialog-header {
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
  background-color: #4A8EDC;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 12px;
}

.am__dialog-title {
  display: table-cell;
  color: #fff;
  font-weight: bold;
}

.am__dialog-content {
  margin: 0;
  text-align: left;
  padding: 20px 15px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  border-radius: 5px;
}

.am__dialog-content p {
  color: #555;
}

.am__dialog-content input {
  width: 100%;
  margin: 4px 0px 50px 0px;
  padding: 8px 10px;
  border: 1px solid #ddd;
  font-size: 13px;
  border-radius: 4px;
}

.am__dialog-btn-wrap {
  text-align: center;
}

.am__dialog-btn {
  color: #666;
  text-decoration: none;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  padding: 6px 12px;
  user-select: none;
  cursor: pointer;
  border: solid 1px #ddd;
  background: linear-gradient(to bottom, #f1f1f1 0%, #e6e6e6 100%);
  width: 135px;
  height: 34px;
}

.am__dialog-btn:hover {
  border: solid 1px #ddd !important;
  background: #e1e1e1 !important;
}

/* DocumentViewerModal */
.DocumentViewerModal {
  height: 100vh;
  display: block;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: #F3F3F3;
  z-index: 9999;
}

.dvm__header {
  height: 44px;
  line-height: 44px;
  background-color: #4A8EDC;
  font-size: 12px;
  color: #fff;
}

.dvm__header-wrap {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.dvm__header-title {
  padding-left: 15px;
}

.dvm__header-btn {
  float: right;
  color: #fff;
  font-size: 21px;
  cursor: pointer;
  font-weight: 600;
  background-color: #4A8EDC;
  border: none;
  right: 1px;
  top: 0;
  height: 44px;
  width: 44px;
  position: absolute;
  text-align: center;
}

.dvm__header-download-btn {
  width: 44px;
  height: 44px;
  padding: 15px;
  cursor: pointer;
  position: absolute;
  right: 46px;
}

.dvm__header-btn:hover, .um__header-btn:hover, .dvm__header-download-btn:hover {
  background-color: #3381D5;
}

.dvm__iframe {
  width: 100%;
  height: calc(100% - 44px);
  overflow: auto;
  border: none;
}

.dvm__not-supported {
  display: table;
  width: 100%;
  height: 100%;
}

.dvm__not-supported h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: table-cell;
  vertical-align: middle;
  font-size: 18px;
  font-weight: bold;
  color: #666;
}
/* ExpiredModal */
.ExpiredModal {
  height: 100vh;
  display: block;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: #F3F3F3;
  padding: 8px;
  z-index: 9999;
}

.em__dialog {
  position: relative;
  top: 50%;
  left: 50%;
  width: 490px;
  height: auto;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 7px;
  background-clip: padding-box;
  border-top: none;
}

.em__dialog-header {
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
  background-color: #3A8EDC;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.em__dialog-title {
  display: table-cell;
}

.em__dialog-title img {
  vertical-align: middle;
}

.em__dialog-content {
  margin: 0;
  text-align: center;
  padding: 70px 10px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  border-radius: 5px;
}

/* PasswordModal */
.PasswordModal {
  height: 100vh;
  display: block;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: #F3F3F3;
  padding: 8px;
  z-index: 10000;
}

.pm__dialog {
  position: relative;
  top: 50%;
  left: 50%;
  width: 490px;
  height: auto;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 7px;
  background-clip: padding-box;
  border-top: none;
}

.pm__dialog-header {
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
  background-color: #3A8EDC;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.pm__dialog-title {
  display: table-cell;
}

.pm__dialog-title img {
  vertical-align: middle;
}

.pm__dialog-content {
  margin: 0;
  padding: 10px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  border-radius: 5px;
}

.pm__dialog-button-wrap {
  text-align: center;
  margin: 20px 0;
}

.pm__dialog-button {
  color: #666;
  text-decoration: none;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  padding: 6px 12px;
  user-select: none;
  cursor: pointer;
  border: solid 1px #ddd;
  background: linear-gradient(to bottom, #f1f1f1 0%, #e6e6e6 100%);
  width: 135px;
  height: 34px;
}

.pm__dialog-button:hover {
  border: solid 1px #ddd !important;
  background: #e1e1e1 !important;
}

.pm__item-wrap {
  margin: 5px 0;
}

.pm__item-title {
  width: 35%;
  padding: 5px 20px;
  display: inline-block;
  font-size: 14px;
  color: #4b402c;
  text-align: right;
}

.pm__item-input {
  width: 35%;
  margin: 5px 20px;
  padding: 8px 10px;
  border: 1px solid #ddd;
  font-size: 13px;
  border-radius: 4px;
}

.pm__item-content {
  width: 65%;
  padding: 5px 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #3793ce;
  float: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* UploadModal */
.UploadModal {
  height: 100vh;
  display: block;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: #00000080;
  padding: 8px;
  z-index: 9999;
}

.um__dialog {
  position: relative;
  top: 50%;
  left: 50%;
  max-width: 800px;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 7px;
  background-clip: padding-box;
  background-color: #fff;
  border-top: none;
  margin-bottom: 100px;
}

.um__header {
  background-color: #4A8EDC;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.um__header-btn {
  float: right;
  color: #fff;
  font-size: 21px;
  cursor: pointer;
  font-weight: 600;
  background-color: #4A8EDC;
  border: none;
  right: 1px;
  top: 0;
  height: 43px;
  padding-top: 5px;
  width: 48px;
  position: absolute;
  text-align: center;
  border-top-right-radius: 5px;
}

.um__dialog-content {
  padding: 30px 70px;
  font-size: 14px;
  color: #333333;
  border-radius: 5px;
}

.um__dialog-content textarea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 8px 10px;
  border: 1px solid #ddd;
  font-size: 13px;
  border-radius: 4px;
}

.um__dialog-content-btn {
  border: 1px solid #333333;
  border-radius: 20px;
  padding: 5px 18px;
  margin-right: 4px;
  cursor: pointer;
  font-size: 12px;
}

.um__dialog-content-btn:hover {
  background-color: #EAEAEA;
}

.um__dialog-content-input {
  width: 100%;
  margin: 0px 0px 10px 0px;
  padding: 8px 10px;
  border: 1px solid #ddd;
  font-size: 13px;
  border-radius: 4px;
}

.um__dialog-content-input::placeholder {
  font-size: 13px;
  color: #666;
}

.um__dialog-send {
  color: #666;
  text-decoration: none;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  padding: 6px 12px;
  user-Select: none;
  cursor: pointer;
  border: solid 1px #ddd;
  background: linear-gradient(to bottom, #f1f1f1 0%, #e6e6e6 100%);
  width: 135px;
  height: 34px;
  margin: 20px 0px;
  float: right;
}

.um__dialog-send:hover {
  border: solid 1px #ddd !important;
  background: #e1e1e1 !important;
}

/* UploadProcessModal */
.UploadProcessModal {
  height: 100vh;
  display: block;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: rgba( 0, 0, 0, 0.5 );
  border-radius: 5px;
  padding: 8px;
  z-index: 9999;
}

.upm__dialog {
  position: relative;
  top: 50%;
  left: 50%;
  width: 520px;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 7px;
  background-clip: padding-box;
  background-color: #fff;
  font-size: 14px;
  border-top: none;
}

.upm__dialog-title {
  padding: 12px;
  font-size: 12px;
  border-bottom: 1px solid #EAEAEA;
  background-color: #4a8edc;
  color: #fff;
}

.upm__dialog-process {
  padding: 0px 8px;
  color: #BDBDBD;
}

.upm__dialog-file {
  position: absolute;
  margin: 8px;
  color: #fff;
  width: 90%;
}

.upm__dialog-file span {
  margin: 0px 8px;
}

.upm__dialog-send {
  color: #666;
  text-decoration: none;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  padding: 6px 12px;
  user-Select: none;
  cursor: pointer;
  border: solid 1px #ddd;
  background: linear-gradient(to bottom, #f1f1f1 0%, #e6e6e6 100%);
  width: 135px;
  height: 34px;
  float: right;
}

.upm__dialog-send:disabled {
  color: #bdbdbd !important;
}

.upm__dialog-send:hover {
  border: solid 1px #ddd !important;
  background: #e1e1e1 !important;
}

@media (max-width: 767px) {
  .em__dialog, .pm__dialog, .am__dialog {
    width: auto;
  }

  .um__dialog-content {
    padding: 10px;
  }

  .UploadModal {
    padding: 0;
    overflow-y: scroll;
  }

  .UploadProcessModal {
    padding: 0;
  }

  .upm__dialog {
    width: 100%;
  }

  .upm__dialog-process {
    padding: 0;
  }

  .upm__item-info {
    width: 70% !important;
  }

  .upm__item-icon img {
    left: 0 !important;
  }

  .upm__item-loader {
    left: 10px !important;
  }

  .um__dialog, .pm__dialog, .am__dialog {
    top: 0;
    left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    border-radius: 0px;
  }
  
  .um__header, .pm__dialog-header, .am__dialog-header {
    border-radius: 0px;
  }

  .um__drop-text {
    display: none;
  }
}

/* progress */
.progress {
  height: 6px !important;
  background-color: #c9c9c9 !important;
}

.progress-bar {
  background-color: #3498db !important;
}

.bg-success {
  background-color: #3498db !important;
}

.loader-appview {
  height: 100%;
}

.loader-appview svg {
  width: 100px;
  height: 100px;
}

.video-js .vjs-control-bar {
  position: fixed !important;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  border-color: none !important;
}

.vjs-button, .vjs-control-text,
.vjs-button:focus, .vjs-control-text:focus {
  outline-style: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

.ShieldModal {
  height: 100vh;
  display: block;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: #00000080;
  padding: 8px;
  z-index: 9999;
}

.sm__dialog {
  position: relative;
  top: 50%;
  left: 50%;
  max-width: 545px;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 7px;
  background-clip: padding-box;
  background-color: #fff;
  border-top: none;
  margin-bottom: 100px;
}

.sm__header {
  background-color: #4A8EDC;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.sm__header-btn {
  float: right;
  color: #fff;
  font-size: 21px;
  cursor: pointer;
  font-weight: 600;
  background-color: #4A8EDC;
  border: none;
  right: 1px;
  top: 0;
  height: 43px;
  padding-top: 5px;
  width: 48px;
  position: absolute;
  text-align: center;
  border-top-right-radius: 5px;
}

.sm__dialog-content {
  padding: 15px;
  font-size: 14px;
  color: #333333;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .Shield {
    text-align: left !important;
  }
  .shield_download {
    display: none !important;
  }
  .shield_mobile {
    display: block !important;
  }
}
/* Header */
.Header {
  /* height: 55px; */
  height: auto;
  width: 100%;
  line-height: 55px;
  background-color: #4A8EDC;
  display: list-item;
}

.header__logo {
  display: block;
  float: left;
  padding-left: 30px;
  text-decoration: none;
}

.header__logo img {
  vertical-align: middle;
}

.header__lang {
  float: right;
  margin-right: 30px;
  /* position: absolute;
  right: 0; */
}

.header__lang-ul {
  float: right;
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
}

.header__lang-li {
  display: inline-block;
  color: #fff;
  position: relative;
  margin: 0;
  padding: 0;
}

.header__lang-btn, .header__sign-btn {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
}

.header__lang-btn:hover, .header__sign-btn:hover {
  background-color: #3381D5;
  text-decoration: none;
  color: #fff;
}

.header__lang-btn img {
  vertical-align: sub;
  margin: 3px;
}

.header__sign-wrap {
  float: right;
}

.Language {
  display: inline-block;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border-radius: 3px;
  color: #000;
  list-style: none;
  top: 100%;
  left: 0;
  padding: 0;
  border: 1px solid rgba(0,0,0,.15);
}

.language__li {
  padding: 4px 20px;
  text-align: left;
}

.language__li:hover {
  background-color: #EAEAEA;
}

@media (max-width: 767px) {
  .header__logo {
    position: absolute;
    top: 0px;
    left: 10px;
    padding-left: 0px;
  }

  .header__lang {
    margin-right: 0;
  }

  .header__sign-wrap {
    visibility: hidden;
    display: none;
  }
}

@media (max-width: 275px) {
  .header__logo {
    display: none;
  }
}
/* Content */
.Content {
  /* min-height: 95vh; */
  height: 100%;
  overflow: auto;
  padding: 0 20px;
  background-color: #F3F3F3;
}

.content__info-wrap {
  height: 52px;
  color: #616161;
  border-bottom: 1px solid #e5e5e5;
  display: block;
  /* padding: 0 16px; */
}

.content__info-btns {
  padding: 6px;
  display: inline-block;
  line-height: 39px;
}

.content__info-btns button {
  color: #fff;
  background-color: #428bca;
  border: 1px solid #357ebd;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
  padding: 6px 10px;
  font-weight: 400;
  line-height: 1.42857143;
  font-size: 13px;
  margin: 0px 4px;
  cursor: pointer;
  width: 110px;
}

.content__info-btns button:hover {
  background-color: #3276BA;
}

.content__info-btns .fa-refresh {
  width: 14px;
  height: 14px;
  padding: 8px 12px;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  display: inline;
}

.content__info-btns .fa-refresh:hover {
  background-color: #ebebeb;
  border-color: #adadad;
}

.content__info-btns .fa-arrow-left {
  width: 14px;
  height: 14px;
  padding: 8px 12px;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  display: inline;
}

.content__info-btns .fa-arrow-left:hover {
  background-color: #ebebeb;
  border-color: #adadad;
}


.content__info {
  color: #616161;
  font-size: 14px;
  padding-top: 17px;
  display: flex;
  float: right;
}

.content__info span {
  margin-right: 20px;
  display: inline-block;
  font-size: 12px;
}

.content__info-sender {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content__info-sizewrap {
  /* padding: 4px 0px 8px 4px; */
  text-align: left;
  display: inline-block;
  background-color: #fff;
  font-size: 12px;
  margin-left: 4px;
  /* border-radius: 4px;
  border: 1px solid #F3F3F3; */
  vertical-align: middle;
  line-height: 30px;
  float: right;
}

.content__info-sizewrap span {
  padding-right: 10px;
}

.content__dialog {
  position: relative;
  max-width: 365px;
  top: 45%;
  left: 50%;
  height: auto;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  border-radius: 4px;
  background-clip: padding-box;
  font-size: 14px;
  text-align: center;
}

.content__down-text, .content__up-text {
  visibility: visible;
}

.content__down-i, .content__up-i {
  visibility: hidden;
  display: none !important;
}
.cl-li-mobile {
  visibility: hidden;
  display: none;
  font-size: 13px;
}


/* ContentDownloadView */
.ContentDownloadView {

}

.cdw__sender {
  padding: 10px 0px;
  color: #333333;
  display: inline-block;
}

.cdw__sender-text {
  font-weight: 600;
}

.cdw__file-wrap {
  background: #fff;
  text-align: center;
  padding: 40px 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: #333333;
}

.cdw__file-img {
  width: 48px;
  height: 48px;
}

.cdw__file-btn {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.42857143;
  font-size: 14px;
  margin: 20px 4px;
  cursor: pointer;
  min-width: 120px;
}

.cdw__file-btn:hover {
  background-color: #3276BA;
}

/* ContentList */
.ContentList {
  height: calc(100% - 100px);
  overflow: auto;
  display: contents;
}

.cl__li-t {
  padding: 6px;
  font-size: 14px;
  color: #333333;
  border-bottom: 1px solid #DBDBDB;
  line-height: 34px;
}

.cl__li {
  padding: 6px;
  font-size: 14px;
  line-height: 32px;
  height: 45px;
  color: #333333;
  border-bottom: 1px solid #DBDBDB;
}

.cl__li:hover {
  background-color: #EAEAEA;
}

.cl__li-name {
  display: inline-block;
  width: 47%;
  cursor: pointer;
}

.cl__li-name-t {
  display: inline-block;
  width: 47%;
}

.cl__li-name-t span{
  cursor: pointer;
}

.cl__li-img {
  width: 28px;
  height: 28px;
  margin: 4px;
  margin-right: 8px;
  vertical-align: middle;
  float: left;
  margin-top: 2px;
}

.cl__li-name input, .cl__li-name-t input {
  margin: 0 6px;
  cursor: pointer;
}

.cl__li-name h4 {
  cursor: pointer;
  margin: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  width: calc(90%);
  vertical-align: middle;
  font-size: 14px;
  margin-bottom: 3px;
  line-height: 32px;
}

.cl__li-info-t, .cl__li-info {
  display: inline-block;
  width: 21%;
  vertical-align: middle;
  float: right;
  height: 32px;
  line-height: 32px;
}

.cl__li-info-t span {
  cursor: pointer;
}

/* ContentUploadView */
.ContentUploadView {
  padding: 40px 10px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  color: #333333;
}

.ContentUploadView button {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
  padding: 6px 10px;
  font-weight: 400;
  line-height: 1.42857143;
  font-size: 14px;
  margin: 20px 4px;
  cursor: pointer;
  min-width: 120px;
}

.ContentUploadView button:hover {
  background-color: #3276BA;
}

.cuv__text {
  background-color: #DBDBDB;
  padding: 4px;
}

.cuv__size-wrap {
  margin: 16px 0px;
  padding-bottom: 8px;
  padding-left: 8px;
  border-bottom: 1px solid #DBDBDB;
  text-align: left;
}

.cuv__size-wrap span {
  padding-right: 10px;
}

.content__upload {
  font-size: 14px;
  text-align: center;
  padding-top: 100px;
  height: calc(100% - 100px);
}

.content__upload-wrap {
  width: 490px;
  margin: 0 auto;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
}

.content__upload-title {
  background-color: #3a8edc;
  padding: 12px;
  text-align: left;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: bold;
}

.content__upload-title img {
  float: right;
}

.content__upload-content {
  background-color: #fff;
  padding: 60px 30px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.content__upload-content p {
  margin-bottom: 0;
}

@media (max-width: 1024px) and (orientation: landscape) {
  .Content {
    padding: 0;
  }

  .content__info-wrap {
    height: auto;
    padding: 0;
    /* border-bottom: 0px; */
  }

  .content__info {
    padding: 7px;
    float: none;
  }

  .content__info span {
    margin-right: 4px;
    display: inline-block;
    font-size: 12px;
  }

  .content__info-btns button {
    width: auto;
  }

  .content__down-i, .content__up-i {
    visibility: visible;
    display: block !important;
  }

  .content__info-cr {
    /* display: none;
    visibility: hidden; */
  }

  .cl__li {
    padding: 12px !important;
    height: auto !important;
  }

  .cl__li-name {
    width: 70% !important;
  }

  .cl__li-name h4 {
    overflow: unset !important;
    text-overflow: unset !important;
    white-space: unset !important;
  }

  .cl__li-name-folder {
    width: 100%;
  }

  .cl__li-name-f {
    margin-top: 10px !important;
  }

  /* 모바일에서 사라지는 부분 */
  .content__down-text, .content__up-text {
    visibility: hidden;
    display: none;
  }

  .cl__li-t {
    visibility: hidden;
    display: none;
  }

  .cl__li-info {
    visibility: hidden;
    display: none !important;
  }

  .cl__li-input {
    visibility: hidden;
    display: none;
  }

  .content__down-wrap {
    visibility: hidden;
    display: none !important;
  }

  .cl__li-download {
    display: inline-block !important;
    visibility: visible !important;
    float: right;
  }

  .cl__li-download button {
    border-radius: 4px;
    padding: 2px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    margin: 6px;
  }

  /* 모바일에서만 보이게 */
  .cl-li-mobile {
    visibility: visible;
    display: inline-block;
    color: #b1b1b1;
    font-weight: normal;
  }

  .cl__li-img {
    margin-top: 3px !important;
  }

  .cl__li-name .cl__li-name-f {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !;
  }
}

@media (max-width: 1280px) {
  .Content {
    padding: 0;
  }

  .content__info-wrap {
    height: auto;
  }

  .content__info {
    padding: 10px;
    float: none;
  }

  .content__info span {
    margin-right: 4px;
    display: inline-block;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .Content {
    padding: 0;
    width: 100%;
  }

  .content__info-wrap {
    height: auto;
    padding: 0;
    /* border-bottom: 0px; */
  }

  .content__info {
    padding: 7px;
    display: flex;
    flex-flow: wrap;
    float: none;
  }

  .content__info span {
    margin-right: 4px;
    display: inline-block;
    font-size: 12px;
  }

  .content__info-btns button {
    width: auto;
  }

  .content__down-i, .content__up-i {
    visibility: visible;
    display: block !important;
  }

  .content__info-cr {
    /* display: none;
    visibility: hidden; */
  }

  .cl__li {
    padding: 12px !important;
    height: auto !important;
  }

  .cl__li-name {
    width: 70% !important;
  }

  .cl__li-name h4 {
    overflow: unset !important;
    text-overflow: unset !important;
    white-space: unset !important;
    word-break: break-all;
    line-height: 16px;
  }

  .cl__li-name-folder {
    width: 100%;
  }

  .cl__li-name-f {
    margin-top: 10px !important;
  }

  /* 모바일에서 사라지는 부분 */
  .content__down-text, .content__up-text {
    visibility: hidden;
    display: none;
  }

  .cl__li-t {
    visibility: hidden;
    display: none;
  }

  .cl__li-info {
    visibility: hidden;
    display: none !important;
  }

  .cl__li-input {
    visibility: hidden;
    display: none;
  }

  .content__down-wrap {
    visibility: hidden;
    display: none !important;
  }

  .cl__li-download {
    display: inline-block !important;
    visibility: visible !important;
    float: right;
  }

  .cl__li-download button {
    border-radius: 4px;
    padding: 2px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    margin: 6px;
  }

  /* 모바일에서만 보이게 */
  .cl-li-mobile {
    visibility: visible;
    display: inline-block;
    color: #b1b1b1;
    font-weight: normal;
  }

  .cl__li-img {
    margin-top: 3px !important;
  }

  .cl__li-name .cl__li-name-f {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !;
  }

  .content__upload-wrap {
    width: auto;
    border-radius: 0px;
  }

  .content__upload-title {
    border-radius: 0px;
  }

  .content__upload {
    padding-top: 30px;
  }

  .content__info-sizewrap {
    float: none;
    margin-top: 4px;
  }
}

@media (max-width: 310px) {
  .cl__li-name {
    width: 60% !important;
  }
}

@media (max-width: 275px) {
  .cl__li-name {
    width: 50% !important;
  }
}

@media (max-width: 200px) {
  .cl__li-name {
    width: 25% !important;
  }
}

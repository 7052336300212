*:focus {
  outline: none;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  user-select: none;
  background-color: #fff;
  font-family: "Open Sans", "Meiryo UI", "맑은 고딕", "Malgun Gothic", "돋움",
    dotum, Helvetica, sans-serif, FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

i {
  margin: 3px;
}
